<template>
    <div class="wrap">
        <div class="header clearfix">
            <p class="rule fl" @click="showRule = true"></p>
            <p class="close-game fr" @click="close"><img src="../../assets/newcard/image/return.png" alt=""></p>
        </div>
        <div class="balance">
            <span>余额：</span>
            <span>{{ gold }}</span>
        </div>
        <div class="main">
            <!-- <h2> 余额 <span>{{ gold }}</span>,单次抽奖:{{ game.price }}</h2> -->
            <ul class="box">
                <li class="animate1" @click="check('animate1', 'one')">
                    <img src="~@/assets/card/image/pattern1.png">
                </li>
                <li class="animate2" @click="check('animate2', 'one')">
                    <img src="~@/assets/card/image/pattern2.png">
                </li>
                <li class="animate3" @click="check('animate3', 'one')">
                    <img src="~@/assets/card/image/pattern3.png">
                </li>
                <li class="animate4" @click="check('animate4', 'one')">
                    <img src="~@/assets/card/image/pattern4.png">
                </li>
                <li class="animate5" @click="check('animate5', 'one')">
                    <img src="~@/assets/card/image/pattern1.png">
                </li>
                <li class="animate6" @click="check('animate6', 'one')">
                    <img src="~@/assets/card/image/pattern2.png">
                </li>
            </ul>
            <div class="flex">
                <div class="btn">
                    <img :src="oneImg" alt="" @click="check('', 'one')">
                    <div>{{priceNum}}橙币</div>
                </div>
                <div class="btn">
                    <img :src="tenImg" alt="" @click="check('', 'ten')">
                    <div>{{priceNum * 10}}橙币</div>
                </div>
                <div class="btn">
                    <img :src="hundredImg" alt="" @click="check('', 'hundred')">
                    <div>{{priceNum * 100}}橙币</div>
                </div>
            </div>
        </div>
      
		<div id="mask-card"></div>
        <rule   :content="game.remark" v-show="showRule" @close="showRule = false" />
        <prize v-if="showPrize" :prize="prize" @close="reset" />

        <audio id="audio" ref="audio">
         <source src="../../assets/kunpeng/music/giftm.mp3" type="audio/wav" />
    </audio>
    </div>


</template>

<script>
import prize from "@/components/prize";
import rule from "@/components/rule";
import '@/assets/newcard/css/index.css'
import { Toast } from "vant";


import one_at from '../../assets/newcard/image/one_at.png';
import hundred_at from '../../assets/newcard/image/hundred_at.png';
import ten_at from '../../assets/newcard/image/ten_at.png';

import one from '../../assets/newcard/image/one.png';
import hundred from '../../assets/newcard/image/hundred.png';
import ten from '../../assets/newcard/image/ten.png';

import rotatem from '../../assets/kunpeng/music/rotatem.mp3';
import giftm from '../../assets/kunpeng/music/giftm.mp3';

export default {
    name: "index",
    components: {
        prize,
        rule
    },
    data() {
        return {
            showRule: false,
            showPrize: false,
            prize: null,
            game: null,
            gold: 0,
            code: 'newcard',
            inDraw: false,
            bool: false,
            clickTime: 0,
            catchDom: 'animate1',
            oneImg: one,
            hundredImg: hundred,
            tenImg: ten,
            priceNum: 0,
        }
    },
    mounted() {
		console.log(33)
        this.getInfo()
    },
 
    watch: {
        'globalData.XPLATFORM': {
            handler(newVal) {
                newVal && this.getInfo()
            },
        }
    },
    methods: {
        getInfo() {
            // if (this.globalData.XPLATFORM) {
                this.$http.get('lottery-game/info', {
                    params: { code: this.code }, headers: {
                        'X-PLATFORM': this.globalData.XPLATFORM
                    }
                }).then((res) => {
                    this.game = res.data;
					 this.priceNum = res.data.price
                    this.gold = res.data['gold']
                    this.$nextTick(() => {
                        this.initJquery()
                    })
                })
        //  }
        },
        reset() {
            this.showPrize = false;
            this.getInfo()
        },
        initJquery() {
            const that = this
            $(function () {
                let $cards = $(".box li"),//所有卡牌
                    length = $cards.length,
                    index = length,//轮流滚动的卡牌下标
                    rem = 75,
                    initArr = [[34 / rem, 0], [273 / rem, 0], [512 / rem, 0], [34 / rem, 268 / rem], [273 / rem, 268 / rem], [512 / rem, 268 / rem]],//卡牌位置数组
                    timer;//轮流提示定时器
                that.clickTime = 0;
                that.bool = false;//首次点击时不能在卡牌归位期间
                //卡牌归位动画
                for (let i = 0; i < length; i++) {
                    (function (i) {
                        let seat = setTimeout(function () {
                            $cards.eq(i).css({
                                left: initArr[i][0] + "rem",
                                top: initArr[i][1] + "rem"
                            });
                            clearTimeout(seat);
                        }, 300 * i);
                    })(i);
                }

                //卡牌轮流选中动画
                setTimeout(function () {
                    timer = setInterval(function () {
                        that.bool = true;//卡牌归位，可以点击
                        $cards.eq(index - 1).removeClass("active");
                        index %= 6;
                        $cards.eq(index).addClass("active");
                        index++;
                    }, 1000);
                }, 1000);


            });


        },
        check(dom, type) {
            if (!this.globalData.XPLATFORM) {
                return
            }
            if (!this.bool) {
                return
            }
            dom && (this.catchDom = dom);
            //点击卡牌翻转
            const that = this;
            const $maskCard = $("#mask-card");//翻牌遮罩
            let num = 'price'
            if (type == 'one') {
                this.oneImg = one_at;
                this.hundredImg = hundred;
                this.tenImg = ten;
            }
            else if (type == 'hundred') {
                num = 'hundred_price';
                this.oneImg = one;
                this.hundredImg = hundred_at;
                this.tenImg = ten;
            } else if (type == 'ten') {
                num = 'ten_price';
                this.oneImg = one;
                this.hundredImg = hundred;
                this.tenImg = ten_at;
            };
            if (new Date() - that.clickTime > 2000 && that.bool) {//两次点击的间隔不能小于2秒

                if (parseInt(that.gold) < parseInt(that.game[num])) {
                    return Toast.fail({ message: '余额不足', position: 'bottom' })
                }
                let requestCompleted = false
                let animateCompleted = false;
                that.$http.post('lottery-game/draw', { type, code: that.code, room_id: that.globalData.roomId }, {
                    headers: { 'X-PLATFORM': this.globalData.XPLATFORM }
                }).then((res) => {
                    that.prize = res
                    requestCompleted = true;
                    that.shouldShowPrize(animateCompleted, requestCompleted)
                });
                $maskCard.show();
                $('.' + that.catchDom).addClass("open-card");//添加翻牌动画
                //动画监听
                $('.' + that.catchDom).on("animationend", function () {
                    $('.' + that.catchDom).removeClass("open-card");//移除翻牌动画
                    $maskCard.hide();
                    $('.' + that.catchDom).off("animationend");//解绑动画监听
                    animateCompleted = true
                    that.shouldShowPrize(animateCompleted, requestCompleted)
                });

            }
        },
        shouldShowPrize(a, b) {
            if (a && b) {
                this.$refs.audio.load();
                this.$refs.audio.play()
                this.showPrize = true;
            }
        }

    }
}
</script>

<style scoped></style>
